import React, {Component} from 'react';
import {Button, TextField, Grid, Divider, Typography, InputLabel, FormControl, FormHelperText, NativeSelect, Input} from '@material-ui/core';
import {CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe} from 'react-stripe-elements';
import CircularProgress from '@material-ui/core/CircularProgress';
import validator from 'validator';

//const env_domain = 'https://tools.dev.trunkpixel.com';
const env_domain = 'https://tools.trunkpixel.com';
//const env_domain = 'http://tools.trunkpixel';

const classes = {
	StripeStyle: {
		base: {
			'::placeholder': {
				color: 'rgba(0,0,0,.4)'
			}
		}
	}
}

class CheckoutForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			complete: false,
			token: '',
			fname: '',
			lname: '',
			email: '',
			phone: '',
			address1: '',
			address2: '',
			city: '',
			state: '',
			zipcode: '',
			fname_error: '',
			lname_error: '',
			email_error: '',
			phone_error: '',
			address1_error: '',
			address2_error: '',
			city_error: '',
			state_error: '',
			zipcode_error: '',
			card_error: '',
			expiry_error: '',
			cvc_error: ''
		};
		this.state_abbr = [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ];
		//this.states = ['Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming'];
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleStripeChange = this.handleStripeChange.bind(this);
	}

	handleSubmit(ev) {
		
		ev.preventDefault();

		this.setState({
			loading: true
		});

		// Validate form
		if (validator.isEmpty(this.state.fname))
			this.setState({fname_error: 'Please enter your first name.'});
		if (validator.isEmpty(this.state.lname))
			this.setState({lname_error: 'Please enter your last name.'});
		if (validator.isEmpty(this.state.phone))
			this.setState({phone_error: 'Please enter your phone.'});
		else if (!validator.isMobilePhone(this.state.phone))
			this.setState({phone_error: 'Please enter a valid phone.'});
		if (validator.isEmpty(this.state.email))
			this.setState({email_error: 'Please enter your email.'});
		else if (!validator.isEmail(this.state.email))
			this.setState({email_error: 'Please enter a valid email.'});
		if (validator.isEmpty(this.state.address1))
			this.setState({address1_error: 'Please enter your address.'});
		if (validator.isEmpty(this.state.city))
			this.setState({city_error: 'Please enter your city.'});
		if (validator.isEmpty(this.state.state))
			this.setState({state_error: 'Please enter your state.'});
		else if (!validator.isIn(this.state.state.toUpperCase(), this.state_abbr))
			this.setState({state_error: 'Please enter a valid state.'});
		if (validator.isEmpty(this.state.zipcode))
			this.setState({zipcode_error: 'Please enter your zipcode.'});
		else if (!validator.isPostalCode(this.state.zipcode, 'US'))
			this.setState({zipcode_error: 'Please enter a valid zipcode.'});

		this.props.stripe.createToken({
			name: this.state.fname+' '+this.state.lname,
			address_line1: this.state.address1,
			address_line2: this.state.address2,
			address_city: this.state.city,
			address_state: this.state.state,
			address_zip: this.state.zipcode,
			address_country: 'US'
		})
		.then((data) => {
			if (data.hasOwnProperty('error')) {
				this.setState({loading: false});
				let api_errors = ['api_connection_error', 'api_error', 'authentication_error', 'idempotency_error', 'invalid_request_error', 'rate_limit_error'];
				if (api_errors.indexOf(data.error.type) !== -1) {
					this.setState({card_error: 'There was a temporary issue. Please try again.'});
				} else { 					
					this.setState({card_error: data.error.message});
				}
			} else {

				this.setState({
					token: data.token.id
				});

				const request_body = {
					token: this.state.token,
					fname: this.state.fname,
					lname: this.state.lname,
					email: this.state.email,
					phone: this.state.phone,
					address1: this.state.address1,
					address2: this.state.address2,
					city: this.state.city,
					state: this.state.state,
					zipcode: this.state.zipcode,
					country: 'US'
				}
	
				fetch(env_domain+"/cart/checkout", {
					method: "POST",
					headers: {
						'Accept': 'application/json'
					},
					mode: 'cors',
					cache: "no-cache",
					body: JSON.stringify(request_body),
					credentials: 'include'
				})
				.then((response) => response.json())
				.then((data) => {
					if (data.success) {
						this.setState({
							loading: false,
							complete: true,
							token: '',
							fname: '',
							lname: '',
							email: '',
							phone: '',
							address1: '',
							address2: '',
							city: '',
							state: '',
							zipcode: '',
							fname_error: '',
							lname_error: '',
							email_error: '',
							phone_error: '',
							address1_error: '',
							address2_error: '',
							city_error: '',
							state_error: '',
							zipcode_error: '',
							card_error: '',
							expiry_error: '',
							cvc_error: ''
						});
						this.props.complete();
					} else if (data.errors) {
						this.setState({loading: false});
						for (var k in data.errors) {
							this.setState({
								[k+"_error"]: data.errors[k]
							});
						}
					}
				}).catch((error) => console.error(error));

			}
		}).catch((error) => console.error(error));

	}

	handleChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
			[event.target.name+"_error"]: ''
		});
		if (event.target.name === 'zipcode' && validator.isPostalCode(event.target.value, 'US')) {
			this.props.updateDestination(event.target.value);
		}
	}

	handleStripeChange(event) {
		let field_name = '';
		if (event.elementType === 'cardNumber') {
			field_name = 'card';
		} else if (event.elementType === 'cardExpiry') {
			field_name = 'expiry';
		} else if (event.elementType === 'cardCvc') {
			field_name = 'cvc';
		}
		this.setState({
			[field_name]: event.value !== undefined ? event.value : ''
		});
		if (event.error !== undefined) {
			this.setState({
				[field_name+"_error"]: event.error.message
			});
		} else {
			this.setState({
				[field_name+"_error"]: ''
			});
		}
	}

	render() {
		return (
			<form onSubmit={this.handleSubmit} noValidate>
				<div className="CheckoutValues">
					<Typography variant="subtitle1" className="CartHeading">Shipping Information</Typography>
					<Grid container>
						<Grid item xs={12}>
							<TextField
								error={this.state.fname_error ? true : false}
								helperText={this.state.fname_error}
								id="checkout-fname"
								name="fname"
								label="First Name"
								value={this.state.fname}
								placeholder="Pat"
								fullWidth
								margin="normal"
								onChange={this.handleChange}
								InputProps={{
									disableUnderline: true,
									classes: {
										root: "AddressRoot",
									  	input: "AddressElement",
									}
								}}
								InputLabelProps={{
									shrink: true,
									classes: {
										formControl: "AddressLabel"
									}
								}}
							/>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							<TextField
								error={this.state.lname_error ? true : false}
								helperText={this.state.lname_error}
								id="checkout-lname"
								name="lname"
								label="Last Name"
								value={this.state.lname}
								placeholder="Pixels"
								fullWidth
								margin="normal"
								onChange={this.handleChange}
								InputProps={{
									disableUnderline: true,
									classes: {
										root: "AddressRoot",
									  	input: "AddressElement",
									}
								}}
								InputLabelProps={{
									shrink: true,
									classes: {
										formControl: "AddressLabel"
									}
								}}
							/>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							<TextField
								error={this.state.email_error ? true : false}
								helperText={this.state.email_error}
								id="checkout-email"
								name="email"
								type="email"
								label="Email"
								value={this.state.email}
								placeholder="pat.pixels@trunkpixels.com"
								fullWidth
								margin="normal"
								onChange={this.handleChange}
								InputProps={{
									disableUnderline: true,
									classes: {
										root: "AddressRoot",
									 	 input: "AddressElement",
									}
								}}
								InputLabelProps={{
									shrink: true,
									classes: {
										formControl: "AddressLabel"
									}
								}}
							/>

						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							<TextField
								error={this.state.phone_error ? true : false}
								helperText={this.state.phone_error}
								id="checkout-phone"
								name="phone"
								type="tel"
								label="Phone"
								value={this.state.phone}
								placeholder="888-589-9849"
								fullWidth
								margin="normal"
								onChange={this.handleChange}
								InputProps={{
									disableUnderline: true,
									classes: {
										root: "AddressRoot",
									  	input: "AddressElement"
									}
								}}
								InputLabelProps={{
									shrink: true,
									classes: {
										formControl: "AddressLabel"
									}
								}}
							/>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							<TextField
								error={this.state.address1_error ? true : false}
								helperText={this.state.address1_error}
								id="checkout-address1"
								name="address1"
								type="text"
								label="Address"
								value={this.state.address1}
								placeholder="589 Pixels St."
								fullWidth
								margin="normal"
								onChange={this.handleChange}
								InputProps={{
									disableUnderline: true,
									classes: {
										root: "AddressRoot",
									  	input: "AddressElement",
									}
								}}
								InputLabelProps={{
									shrink: true,
									classes: {
										formControl: "AddressLabel"
									}
								}}
							/>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							<TextField
								error={this.state.address2_error ? true : false}
								helperText={this.state.address2_error}
								id="checkout-address2"
								name="address2"
								type="text"
								label="Address Line 2 (Optional)"
								value={this.state.address2}
								placeholder="Apt. 9"
								fullWidth
								margin="normal"
								onChange={this.handleChange}
								InputProps={{
									disableUnderline: true,
									classes: {
										root: "AddressRoot",
									  	input: "AddressElement",
									}
								}}
								InputLabelProps={{
									shrink: true,
									classes: {
										formControl: "AddressLabel"
									}
								}}
							/>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
						<TextField
								error={this.state.city_error ? true : false}
								helperText={this.state.city_error}
								id="checkout-city"
								name="city"
								type="text"
								label="City"
								value={this.state.city}
								placeholder="Pixel Town"
								fullWidth
								margin="normal"
								onChange={this.handleChange}
								InputProps={{
									disableUnderline: true,
									classes: {
										root: "AddressRoot",
									 	input: "AddressElement",
									}
								}}
								InputLabelProps={{
									shrink: true,
									classes: {
										formControl: "AddressLabel"
									}
								}}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={16}>
						<Grid item xs={6}>
							<FormControl error={this.state.state_error ? true : false} fullWidth margin="normal">
								<InputLabel className="AddressLabel" htmlFor="checkout-state" shrink={true}>State</InputLabel>
								<NativeSelect
									value={this.state.state}
									onChange={this.handleChange}
									input={<Input name="state" id="checkout-state" disableUnderline={true} className="NoMargin" />}
									classes={{root:"AddressRoot",select:"AddressElement"}}
								>
									{Object.values(this.state_abbr).map((item, key) => (
										<option key={key} value={item}>{item}</option>
									))}
								</NativeSelect>
								<FormHelperText>{this.state.state_error}</FormHelperText>
							</FormControl>
						</Grid>
						<Grid item xs={6}>
							<TextField
								error={this.state.zipcode_error ? true : false}
								helperText={this.state.zipcode_error}
								id="checkout-zipcode"
								name="zipcode"
								type="text"
								label="Zip Code"
								value={this.state.zipcode}
								placeholder="12345"
								fullWidth
								margin="normal"
								onChange={this.handleChange}
								InputProps={{
									disableUnderline: true,
									classes: {
										root: "AddressRoot",
									  	input: "AddressElement",
									}
								}}
								InputLabelProps={{
									shrink: true,
									classes: {
										formControl: "AddressLabel"
									}
								}}
							/>
						</Grid>
					</Grid>
				</div>
				<Divider />
				<div className="CheckoutValues">
					<Typography variant="subtitle1" className="CartHeading">Payment Information</Typography>
					<Grid container>
						<Grid item xs={12}>
							<FormControl fullWidth margin="normal" error={this.state.card_error ? true : false}>
								<InputLabel htmlFor="checkout-card" className="AddressLabel" error={this.state.card_error ? true : false}>Card Number</InputLabel>
								<CardNumberElement id="checkout-card" onChange={this.handleStripeChange} style={classes.StripeStyle} />
								<FormHelperText className="CheckoutHelpText">{this.state.card_error}</FormHelperText>
							</FormControl>
						</Grid>
					</Grid>
					<Grid container spacing={16}>
						<Grid item xs={6}>
							<FormControl fullWidth margin="normal" error={this.state.expiry_error ? true : false}>
								<InputLabel htmlFor="checkout-expiry" className="AddressLabel" error={this.state.expiry_error ? true : false}>Expiration</InputLabel>
								<CardExpiryElement id="checkout-expiry" onChange={this.handleStripeChange} style={classes.StripeStyle} />
								<FormHelperText className="CheckoutHelpText">{this.state.expiry_error}</FormHelperText>
							</FormControl>
						</Grid>
						<Grid item xs={6}>
							<FormControl fullWidth margin="normal" error={this.state.cvc_error ? true : false}>
								<InputLabel htmlFor="checkout-cvc" className="AddressLabel" error={this.state.cvc_error ? true : false}>CVC</InputLabel>
								<CardCVCElement id="checkout-cvc" onChange={this.handleStripeChange} style={classes.StripeStyle} />
								<FormHelperText className="CheckoutHelpText">{this.state.cvc_error}</FormHelperText>
							</FormControl>
						</Grid>
					</Grid>
				</div>
				<div className="CheckoutValues">
					<Grid container>
						<Grid item xs={12}>
							<div className="wrapper">
								<Button
									type="submit"
									className="checkoutButton"
									variant="contained"
									size="large"
									color="primary"
									fullWidth 
									margin="normal"
									disableRipple={true}
									disabled={this.state.loading}
									>
								Pay ${this.props.total.toFixed(2)}
								</Button>
								{this.state.loading ? (<CircularProgress size={24} className="buttonProgress" />) : null}
							</div>
						</Grid>
					</Grid>
				</div>
				<div className="CheckoutValues">
					<Grid container>
						<Grid item xs={12}>
							<Typography variant="body2">
								By placing this order you agree to the <a href="/terms.html" target="_blank">terms and conditions</a> of this website.
							</Typography>
						</Grid>
					</Grid>
				</div>
			</form>
		);
	}

}

export default injectStripe(CheckoutForm);