import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import './App.css';

// Material UI stuff
import 'typeface-roboto';
import {Grid, GridList, GridListTile, Paper, Card, Button, Typography, AppBar, Toolbar, Hidden, List, ListItem, ListItemText, Drawer, FormControl, Select, Badge} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import {blue, deepOrange, cyan, lightBlue, red} from '@material-ui/core/colors';

// Stripe
import {Elements,StripeProvider} from 'react-stripe-elements';
import CheckoutForm from './CheckoutForm.js';

// React scroll
import {Link,Element} from 'react-scroll'

// Icons
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import ShoppingCartOutlined from '@material-ui/icons/ShoppingCartOutlined';
import RemoveShoppingCartOutlined from '@material-ui/icons/RemoveShoppingCartOutlined';
import TouchAppOutlined from '@material-ui/icons/TouchAppOutlined';
import {withStyles} from '@material-ui/core/styles';

import stickybits from 'stickybits'

//const stripe_key = 'pk_test_y0ryTiXX8vT3Mo6OY1VrOFtF';
const stripe_key = 'pk_live_mYzE3f2R9rtn4KfItdmcsUOe';
//const env_domain = 'https://tools.dev.trunkpixel.com';
const env_domain = 'https://tools.trunkpixel.com';
//const env_domain = 'http://tools.trunkpixel';

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true; // Material UI thing
const styles = theme => ({
	palette: {
		primary: blue,
		secondary: deepOrange
	},
	root: {
		flexGrow: 1,
	},
	flex: {
		flexGrow: 1
	},
	RightIcon: {
		marginLeft: 10,
		verticalAlign: 'middle'
	},
	toolbar: {
		justifyContent: 'space-between'
	},
	blueAvatar: {
		backgroundColor: red[500]
	},
	deepOrangeAvatar: {
		backgroundColor: deepOrange[500]
	},
	cyanAvatar: {
		backgroundColor: cyan[500]
	},
	lightBlueAvatar: {
		backgroundColor: lightBlue[500]
	},
	salesPaper: {
		paddingBottom: `1.5rem`
	},
	salesCar: {
		overflow: 'hidden'
	},
	bottomSalesPaper: {
		paddingTop: `3rem`,
		paddingBottom: `3rem`
	},
});

class App extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selected_category_id: '',
			selected_category_name: '',
			categories: {},
			pixels: {},
			cart_open: false,
			cart: {},
			total_quantity: 0,
			complete: false
		};
		this.toggleDrawer = this.toggleDrawer.bind(this);
		this.getCart = this.getCart.bind(this);
		this.getInventory = this.getInventory.bind(this);
		this.updateCart = this.updateCart.bind(this);
		this.updateDestination = this.updateDestination.bind(this);
		this.completeOrder = this.completeOrder.bind(this);
		this.isInCart = this.isInCart.bind(this);
	}

	toggleDrawer(state) {
		this.setState({
			cart_open: state,
			complete: false
		});
	};

	getCart() {
		fetch(env_domain+'/cart', {
			credentials: 'include'
		})
		.then((response) => response.json())
		.then((data) => {
			this.setState({
				cart: data
			});
			this.setState({
				total_quantity: data.total_quantity
			});
		})
		.catch((error) => console.error(error));
	};

	getInventory(category) {
		if (!category || category !== this.state.selected_category_id) {
			fetch(env_domain+'/inventory?cat='+category, {
				credentials: 'include'
			})
			.then((response) => response.json())
			.then((data) => {
				this.setState({
					selected_category_id: data.selected_category_id,
					selected_category_name: data.selected_category_name,
					categories: data.categories,
					pixels: data.pixels
				});
				stickybits('.AppBar', {noStyles: true});
			})
			.catch((error) => console.error(error));
		}
	};

	updateCart(id, quant) {
		fetch(env_domain+'/cart/update', {
			method: "POST",
			headers: {
				'Accept': 'application/json'
			},
        	mode: 'cors',
			cache: "no-cache",
			body: JSON.stringify({id: id, quant: quant}),
			credentials: 'include'
		})
		.then((response) => response.json())
		.then((data) => {
			this.setState({
				cart: data,
			});
			this.setState({
				total_quantity: data.total_quantity
			});
		})
		.catch((error) => console.error(error));
	};

	updateDestination(zipcode) {
		fetch(env_domain+'/cart/destination', {
			method: "POST",
			headers: {
				'Accept': 'application/json'
			},
        	mode: 'cors',
			cache: "no-cache",
			body: JSON.stringify({zipcode: zipcode}),
			credentials: 'include'
		})
		.then((response) => response.json())
		.then((data) => {
			this.setState({
				cart: data,
			});
			this.setState({
				total_quantity: data.total_quantity
			});
		})
		.catch((error) => console.error(error));
	};

	completeOrder() {
		this.setState({
			cart: {},
			total_quantity: 0,
			complete: true
		});
	}

	componentDidMount() {
		this.getCart();
		this.getInventory('');
	};

	isInCart(id) {
		if (this.state && this.state.cart && this.state.cart.hasOwnProperty('items') && this.state.cart.items.hasOwnProperty(id))
			return this.state.cart.items[id].quant;
		else
			return 0;
	}

	render() {
		const { classes } = this.props;
		return (
		<React.Fragment>
			<CssBaseline />
			<div className={classes.root}>

				<AppBar position="sticky" color="default" className="AppBar">
					<Toolbar className={classes.toolbar}>
						<Typography variant="h2" color="inherit" className={classes.flex}>
							<a href="/" title="TrunkPixel.com">
								<img src="/img/main_logo.svg" alt="Official TrunkPixel Logo" className="MainLogo" />
							</a>
						</Typography>
						<nav className="MainNav">
							<Hidden smDown>
									{this.state.total_quantity > 0 ? (
										<Button variant="text" color="inherit" disableRipple={true} onClick={() => this.toggleDrawer(this.state.cart_open ? false : true)}>
											<Badge badgeContent={this.state.total_quantity} color="secondary">
												<ShoppingCartOutlined></ShoppingCartOutlined>
											</Badge>
										</Button>
									) : (
										<Button variant="outlined" color="inherit" disableRipple={true} onClick={() => this.toggleDrawer(this.state.cart_open ? false : true)}>
											<span>
												<ShoppingCartOutlined className="BigIcon"></ShoppingCartOutlined>
												Cart
											</span>
										</Button>
									)}
							</Hidden>
							<Hidden mdUp>
								<Button variant="text" size="small" color="inherit" disableRipple={true} onClick={() => this.toggleDrawer(this.state.cart_open ? false : true)}>
									<Badge badgeContent={this.state.total_quantity} color="secondary">
										<ShoppingCartOutlined></ShoppingCartOutlined>
									</Badge>
								</Button>
							</Hidden>
						</nav>
					</Toolbar>
				</AppBar>

				<div className="SplashBanner">
					<Grid container spacing={0} justify="center">
						<Grid item xs={12} md={10} lg={8} xl={6}>
							<Grid container spacing={0} justify="space-between">
								<Grid item xs={12} className={classes.salesCar}>
									<div className="BannerText">&nbsp;</div>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>

				<div className="SalesPitch">
					<Grid container spacing={0} justify="center">
						<Grid item xs={12} md={10} lg={8} xl={6}>
						
							<Paper square={true} className={classes.salesPaper}>
								<Grid container spacing={0} justify="space-between">
									
									<Hidden xsDown>
										<Grid item sm={5} md={5} lg={4} className={classes.salesCar}>
											<img src="/img/truck.png" alt="Get Your Patriot On - Flag Background" className="Truck" />
										</Grid>
									</Hidden>
									
									<Grid item xs={12} sm={7} md={7} lg={8}>

										<Grid container justify="space-evenly">
											<Grid item xs={12}>
												<img src="/img/pixel_previews.svg" alt="Pixel Previews" className="PreviewPixels" />
											</Grid>
										</Grid>

										<Grid container justify="center">
											<Grid item xs={10} sm={12} md={8} lg={6} xl={6}>
												<List classes={{root: 'SalesList'}}>
													<ListItem disableGutters={true}>
														<ListItemText primary='Themed 1" outdoor mini-magnets' />
													</ListItem>
													<ListItem disableGutters={true}>
														<ListItemText primary="Commemorative USA designs" />
													</ListItem>
													<ListItem disableGutters={true}>
														<ListItemText primary="A great cause behind each design" />
													</ListItem>
													<ListItem disableGutters={true}>
														<ListItemText primary="Add a few to create a statement" />
													</ListItem>
													<ListItem disableGutters={true}>
														<ListItemText primary="Show some pride with subtlety" />
													</ListItem>
													<ListItem disableGutters={true}>
														<ListItemText primary="Your car, fridge, or anything else" />
													</ListItem>
												</List>
											</Grid>						
											<Hidden smDown>
												<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
													<img src="/img/splat.png" alt="Flip Your Pixels – Add 4 To Get Discount Red Burst" className="Splat" />
												</Grid>
											</Hidden>

										</Grid>

									</Grid>

								</Grid>
							</Paper>
						
						</Grid>
					</Grid>
				</div>
			
				<div id="PixelPicker" className="PixelPicker">
					<Element name="picker"></Element>
					<Grid container spacing={0} justify="center">
						<Grid item xs={12} md={10} lg={8} xl={6}>
							<Grid container>
								<Grid item xs={12}>
									<Typography variant="h5" align="center" gutterBottom={true} className="TapToAdd">
										<TouchAppOutlined className="BigIcon" style={{ fontSize: 36 }} />Tap Pixels to Select
									</Typography>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={12}>
									<GridList classes={{root:"CategoryListRoot"}}>
										<Categories categories={this.state.categories} selected_category={this.state.selected_category_id} update={this.getInventory} />
									</GridList>
								</Grid>
							</Grid>
							<Grid container className="ForSale" spacing={16} justify="center">
								<Inventory pixels={this.state.pixels} getquant={this.isInCart} update={this.updateCart} />
							</Grid>
							<Grid container>
								<Grid item xs={12}>
									<GridList classes={{root:"CategoryListRoot"}}>
										<Categories categories={this.state.categories} selected_category={this.state.selected_category_id} update={this.getInventory} />
									</GridList>
								</Grid>
							</Grid>

						</Grid>
					</Grid>
				</div>

				<div className="BottomSales">
					<Grid container spacing={0} justify="center">
						<Grid item xs={12} md={10} lg={8} xl={6}>
						
							<Paper square={true} className={classes.bottomSalesPaper}>
								<Grid container spacing={0} justify="space-around">
									
									<Hidden xsDown>
										<Grid item sm={5} md={6} align="right">
											<img className="BottomSalesImg" src="/img/bottom_sales.png" alt="TrunkPixels Support Veterans, Special Services, Children, and Wildlife & Nature Conservation" />
										</Grid>
									</Hidden>

									<Grid item xs={11} sm={7} md={6}>
										<Typography variant="h5">
											Get the 4 Pack Special!
										</Typography>
										<List classes={{root: 'SalesList'}}>
											<ListItem disableGutters={true}>
												<ListItemText primary="Add 4 to your cart for a bulk discount" />
											</ListItem>
											<ListItem disableGutters={true}>
												<ListItemText primary="Pick 1 design or many – any 4+ will do" />
											</ListItem>
											<ListItem disableGutters={true}>
												<ListItemText primary="Add 8+ for FREE SHIPPING & HANDLING" />
											</ListItem>
											<ListItem disableGutters={true}>
												<ListItemText primary="Local sales tax may apply – that's it!" />
											</ListItem>
											<ListItem disableGutters={true}>
												<ListItemText primary="We support: OUR Veterans, OUR Special Services, OUR Children, U.S. Wildlife & Nature Conservation" />
											</ListItem>
										</List>
									</Grid>
								</Grid>

								<Grid container spacing={0} justify="center">
									<Grid item xs={10}>
										<Typography variant="h5" className="callout" align="center">
											The Price is Right So Spread The Love!
										</Typography>
									</Grid>
								</Grid>

							</Paper>
						
						</Grid>
					</Grid>
				</div>

				<footer className="Footer">
					<Grid container spacing={0} justify="center">
						<Grid item xs={12} md={10} lg={8} xl={6}>
							<Grid container spacing={0} className="footerCats">
						{Object.values(this.state.categories).map((item, key) => (
							<Grid key={key} item xs={12} md={6} lg={3}><Typography paragraph={true} variant="body2"><Link to="picker" smooth={true} offset={-100} duration={500} onClick={() => this.getInventory(item.id)}>{item.name}</Link></Typography></Grid>
						))}
							</Grid>
						</Grid>
					</Grid>
					<Grid container spacing={0} justify="center">
						<Grid item xs={12} md={10} lg={8} xl={6}>
							<Grid container spacing={0}>
								<Grid item xs={12} md={6}>
									<img src="/img/white_logo.png" alt="Official TrunkPixel Logo White" />
								</Grid>
								<Grid item xs={12} md={6}>
									<Typography variant="body2" className="Contacts">
										<a href="mailto:support@trunkpixel.com" title="Contact">Contact</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a href="/terms.html" title="Terms and Conditions" target="_blank">Terms</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a href="/privacy.html" title="Privacy Policy" target="_blank">Privacy</a>
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={0}>
								<Grid item xs={12}>
									<Typography variant="body2">
										&copy; {(new Date()).getFullYear()} TrunkPixel. All rights reserved.
									</Typography>
									<Typography variant="body2">
										200 Broadhollow Rd. STE 207
									</Typography>
									<Typography variant="body2">
										Melville, NY 11747
									</Typography>
									<Typography variant="body2">
										(888) 589-9849
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</footer>

				<Drawer
					anchor="right"
					open={this.state.cart_open}
					onClose={() => this.toggleDrawer(false)}
					ModalProps={{disableRestoreFocus: true}}
					PaperProps={{classes:{elevation16:'DrawerPaper'}}}
				>
					<div
						tabIndex={0}
						role="button"
						onClick={() => this.toggleDrawer(false)}
						onKeyDown={() => this.toggleDrawer(false)}
					>
					</div>

					<div className="ShoppingCartContain">
						<Grid container spacing={0} justify="center">
							<Grid item xs={10}>
								<Cart cart={this.state.cart} update={this.updateCart} complete={this.completeOrder} isComplete={this.state.complete} updateDestination={this.updateDestination} />
							</Grid>
						</Grid>
					</div>

				</Drawer>

			</div>
	
		</React.Fragment>

		);
	}
}

class Cart extends Component {

	render() {
		if (this.props.cart.hasOwnProperty('items') && this.props.cart.total_quantity > 0) {
			return (
				<div className="Cart">

					<Typography variant="button" className="CartTitle">
						<ShoppingCartOutlined className="BigIcon"></ShoppingCartOutlined>Shopping Cart ({this.props.cart.total_quantity})
					</Typography>
					
					<Divider />
					
					<Typography variant="body2" className="CartOffer" offercomplete={this.props.cart.total_quantity >= 4 ? 1 : 0}>Bulk discount at 4+ pixels!<CheckCircleOutline className="PromoComplete"></CheckCircleOutline></Typography>
					<Typography variant="body2" className="CartOffer" offercomplete={this.props.cart.total_quantity >= 8 ? 1 : 0}>FREE S&H at 8+ pixels!<CheckCircleOutline className="PromoComplete"></CheckCircleOutline></Typography>

					<div className="CheckoutValues">
						<Typography variant="subtitle1" className="CartHeading">Your Pixels</Typography>
						<Grid container justify="center" className="ShoppingCartItems" spacing={16}>
							<CartItems items={this.props.cart.items} update={this.props.update}  />
						</Grid>
					</div>
					
					<Divider />

					<div className="CheckoutValues">
						<Typography variant="subtitle1" className="CartHeading">Order Summary</Typography>
						<Typography variant="body1" paragraph={true} align="right">
							Subtotal: <span className="MoneyHolder">${(this.props.cart.subtotal_due).toFixed(2)}</span>
						</Typography>
						<Typography variant="body1" paragraph={true} align="right">
							Bulk Discount:<span className={this.props.cart.total_discount === 0 ? "MoneyHolder" : "MoneyHolder NegativeMoney"}>{this.props.cart.total_discount > 0 ? '-' : ''}${(this.props.cart.total_discount).toFixed(2)}</span>
						</Typography>
						<Typography variant="body1" paragraph={true} align="right">
							Sales Tax:<span className="MoneyHolder">${(this.props.cart.tax_due).toFixed(2)}</span>
						</Typography>
						<Typography variant="body1" paragraph={true} align="right">
							Shipping & Handling: <span className={this.props.cart.shipping_due === 0 ? "MoneyHolder NegativeMoney" : "MoneyHolder"}>${(this.props.cart.shipping_due).toFixed(2)}</span>
						</Typography>
						<Typography variant="body1" paragraph={true} align="right">
							Total: <span className="MoneyHolder">${(this.props.cart.total_due).toFixed(2)}</span>
						</Typography>
					</div>
					
					<Divider />

					<StripeProvider apiKey={stripe_key}>
						<Elements>
							<CheckoutForm complete={this.props.complete} total={this.props.cart.total_due} updateDestination={this.props.updateDestination} />
						</Elements>
					</StripeProvider>
			
				</div>
			);
		} else if (this.props.isComplete) {
			return (
				<div>
					<Typography variant="button" className="CartTitle">
						<ShoppingCartOutlined className="BigIcon"></ShoppingCartOutlined>Shopping Cart
					</Typography>
					<Divider />
					<div className="checkoutSuccess">	
						<Typography paragraph={true} variant="h6">
							Your order is complete!
						</Typography>
						<Typography paragraph={true}>
							Please allow 3-7 business days for your TrunkPixels to arrive. Check your email address for your receipt and order details. Contact <a href="mailto:support@trunkpixel.com" title="support@trunkpixel.com">support@trunkpixel.com</a> if you need additional assistance with your order. Check back often for new designs, and follow us on <a href="facebook.com/pg/trunkpixel-1683326725123017" title="Facebook">Facebook</a> and <a href="instagram.com/trunkpixel" title="Instagram">Instagram</a>!
						</Typography>
						<Typography paragraph={true}>
							Thank you for being a TrunkPixel customer... A Slice of American Pride on Every Ride! &trade;
						</Typography>
						<Typography paragraph={true}>
							Thank you!
						</Typography>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<Typography variant="button" className="CartTitle">
						<ShoppingCartOutlined className="BigIcon"></ShoppingCartOutlined>Shopping Cart
					</Typography>
					<Divider />
					<Typography paragraph={true} className="CartText">
						Your cart is empty.
					</Typography>
				</div>
			);
		}
	}

}

class Categories extends Component {

	render() {
		return Object.values(this.props.categories).map((item, key) => (
			<GridListTile key={item.id} classes={{root:"CategoryItemRoot", tile:"CategoryItemTile"}} current={item.id+'' === this.props.selected_category+'' ? "true" : undefined}>
				<Link to="picker" smooth={true} offset={-100} duration={500} id={item.id} onClick={() => this.props.update(item.id)}>{item.name}</Link>
			</GridListTile>
		));
	}

}

class CartItems extends Component {

	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		this.props.update(event.target.id, event.target.value);
	}

	render() {
		return Object.values(this.props.items).map((item, key) => (
			<Grid item xs={12} sm={6} key={item.id}>
				<Card square={true} className="CartCard">
					<div className="holdCover">
						<div className="coverCard">&nbsp;</div>
						<img src={item.props.img} alt={item.props.name} />
					</div>
					<Button size="small" onClick={() => this.props.update(item.id, 0)}><RemoveShoppingCartOutlined></RemoveShoppingCartOutlined></Button>
					<FormControl required className="PixelQuant">
						<Select
							id={item.id}
							native={true}
							value={item.quant}
							onChange={this.handleChange}
						>
							{Array.from(Array(100).keys()).map((num) => (
								<option key={num} value={num}>{num}</option>
							))}
						</Select>
					</FormControl>
				</Card>
			</Grid>
		));
	}

}

class Inventory extends Component {

	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(event) {
		let id = event.target.getAttribute('data-id');
		let img = event.target.parentElement.getElementsByTagName('img')[0];
		let new_img = img.cloneNode();
		new_img.classList.add('cloned_img');
		let viewportOffset = img.getBoundingClientRect();
		new_img.style.maxWidth = img.offsetWidth+'px';
		new_img.style.maxHeight = img.offsetHeight+'px';
		new_img.style.top = viewportOffset.top+"px";
		new_img.style.right = (document.documentElement.clientWidth-(viewportOffset.left+img.offsetWidth))+"px";
		event.target.parentElement.insertBefore(new_img, null);
		setTimeout(function() {
			new_img.classList.add('cloned_img_in_cart');
		}, 50);
		setTimeout(function() {	
			new_img.parentElement.removeChild(new_img);
		}, 1050);
		this.props.update(id, Math.min(this.props.getquant(id)+1, 99));
	}

	render() {
		return Object.values(this.props.pixels).map((item, key) => (
			<Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
				<Card square={true} className="PixelCard">
					<div className="coverCard" data-id={item.id} onClick={this.handleClick}>&nbsp;</div>
					<img className="Pixel"
						src={item.path}
						alt={item.name}
					/>
					{parseInt(this.props.getquant(item.id), 10) > 0 && <div className="pixelSelected">{this.props.getquant(item.id)}</div>}
				</Card>
			</Grid>
		));
	}

}

export default withStyles(styles)(App);